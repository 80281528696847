import { Component, Input, OnInit } from '@angular/core';
import { ModalControllerService } from '@shared/services/modal-controller.service';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { TechLoginActions } from '@tech/pages/login/store/tech-login.actions-type';
import { TenantFilterPipe } from '@shared/pipes/tenant-filter.pipe';
import type { TenantInfoDto } from '@server-models';
import { TeamLoginActions } from '@team/pages/login/store/team-login.actions-type';
import { AppType } from '@shared/models/AppType';
import { LoginBaseSelectors } from '@shared/stores/login-base/store/login-base.selector-type';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { BaseSearchBarComponent } from '@shared/components/search-bar/components/base-search-bar.component';

@Component({
  selector: 'app-tenant-selection-modal',
  templateUrl: './tenant-selection-modal.component.html',
  styleUrls: ['./tenant-selection-modal.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    TenantFilterPipe,
    BaseSearchBarComponent
  ],
  standalone: true,
})
export class TenantSelectionModalComponent implements OnInit {
  @Input() public data!: TenantInfoDto[];

  selectedRoles!: { tenantId: number };
  searchTerm: string = '';
  currentApp: AppType = AppType.Team;

  constructor(
    private _modalCtrlService: ModalControllerService,
    private _store: Store
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentApp = await firstValueFrom(
      this._store.select(LoginBaseSelectors.selectCurrentApp)
    );
  }

  get sortedData() {
    return _.sortBy(this.data, ['displayName']);
  }

  submitSelection(): void {
    switch (this.currentApp) {
      case AppType.Team:
        this._store.dispatch(
          TeamLoginActions.successSelectedTenant(this.selectedRoles)
        );
        break;
      case AppType.Tech:
        this._store.dispatch(
          TechLoginActions.successSelectedTenant(this.selectedRoles)
        );
        break;
    }
    this._modalCtrlService.closeModal();
  }

  exit(): void {
    this._modalCtrlService.closeModal();
    this._store.dispatch(TechLoginActions.cancelSelectedTenant());
  }
}
