import {createAction, props} from "@ngrx/store";
import {
  IssueListDto,
  IssueTemplateListDto,
  StereotypeDto,
  StereotypeListDto,
} from "@server-models";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {IssueDtoType} from "@shared/components/issues/types/issue-dto.type";

export const getById = createAction(
  '[Issues-Team] Get By Id Start',
  props<{ id: number }>()
);

export const getByIdSuccess = createAction(
  '[Issues-Team] Get By Id Success',
  props<{ data: IssueDtoType }>()
);
export const getByIdFail = createAction(
  '[Issues-Team] Get By Id Fail',
  props<{ error: Error }>()
);

export const getItemsPaginatedRefresh = createAction(
  '[Issues-Team] Get Paginated Refresh',
  props<{ data: OrgaResponse<IssueListDto[]> }>()
);

export const getItemsPaginatedCancel = createAction(
  '[Issues-Team] Get Paginated Cancel'
);

export const getItemsPaginatedFail = createAction(
  '[Issues-Team] Get Paginated Fail',
  props<{ error: Error }>()
);

export const getItemsTemplate = createAction(
  '[Issues-Team] Get Template Start'
);

export const getItemsTemplateSuccess = createAction(
  '[Issues-Team] Get Template Success',
  props<{ data: OrgaResponse<IssueTemplateListDto[]> }>()
);
export const getItemsTemplateFail = createAction(
  '[Issues-Team] Get Template Fail',
  props<{ error: Error }>()
);

export const sendTemplatePreview = createAction(
  '[Issues-Team] Send Template Preview Start',
  props<any>()
);

export const sendTemplatePreviewSuccess = createAction(
  '[Issues-Team] Send Template Preview Success',
  props<any>()
);

export const sendTemplatePreviewFail = createAction(
  '[Issues-Team] Send Template Preview Fail',
  props<{ error: Error }>()
);

export const getStereotypeById = createAction(
  '[Issues-Team] Get Stereotype By Id Start',
  props<{ stereotypeId: number, issueId: number, cacheGuid: string }>()
);

export const getStereotypeByIdSuccess = createAction(
  '[Issues-Team] Get Stereotype By Id Success',
  props<{ stereotype: StereotypeDto, cacheGuid: string }>()
);

export const getStereotypeByIdFail = createAction(
  '[Issues-Team] Get Stereotype By Id Fail',
  props<{ error: Error }>()
);

export const getStereotypeByEntityType = createAction(
  '[Issues-Team] Get Stereotype By EntityType Start',
  props<{ entityType: any, cacheControl: string }>()
);

export const getStereotypeByEntityTypeSuccess = createAction(
  '[Issues-Team] Get Stereotype By EntityType Success',
  props<{ data: OrgaResponse<StereotypeListDto> }>()
);

export const getStereotypeByEntityTypeFail = createAction(
  '[Issues-Team] Get Stereotype By EntityType Fail',
  props<{ error: Error }>()
);

export const navigateToNewIssue = createAction(
  '[Issues-Team] Navigate to new'
);

export const navigateToIssues = createAction(
  '[Issues-Team] Navigate to Issues'
);

export const saveSignature = createAction(
  '[Issues-Team] Save signature',
  props<{ blob: Blob; index: number }>()
);

export const saveSignatureSuccess = createAction(
  '[Issues-Team] Save signature Success',
  props<{ id: number; index: number }>()
);

export const saveSignatureFail = createAction(
  '[Issues-Team] Save signature Fail',
  props<{ error: Error }>()
);
