import { Pipe, PipeTransform } from '@angular/core';
import { TenantInfoDto } from '@server-models';

@Pipe({
  name: 'tenantFilter',
  standalone: true
})
export class TenantFilterPipe implements PipeTransform {

  public transform(value: TenantInfoDto[], searchTerm: string): TenantInfoDto[] {
    return searchTerm?.length ? value.filter(tenant => tenant.name?.toLowerCase().includes(searchTerm.toLowerCase())) : value;
  }
}
