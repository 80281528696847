import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {AppEntityType, StereotypeDto, StereotypeListDto} from "@server-models";
import {TeamHttpClientBaseApiService} from "@team/services/http-client-base-api/team-http-client-base-api.service";
import {StereotypeApiBaseDefinitionService} from "@shared/services/stereotype/stereotype-api-base-definition.service";
import {PaginationParams} from "@shared/interfaces/pagination-params.interface";
import {RequestOptions} from "@shared/interfaces/request-options.interface";

@Injectable({
  providedIn: 'root',
})
export class TeamStereotypeApiService extends TeamHttpClientBaseApiService implements StereotypeApiBaseDefinitionService {
  /**
   * @name getStereotypeById
   * @description
   * make a request to stereotype by id
   * @memberof TeamStereotypeApiService
   * @param id
   * @returns {Observable<StereotypeDto>}
   */
  getStereotypeById(id: number): Observable<StereotypeDto> {
    return super.get<StereotypeDto>(`/tech/stereotype/${id}`, undefined);
  }

  /**
   * @name getStereotypeFilterByEntityType
   * @description
   * make a request to stereotype using entityType and cache-control
   * @memberof TeamStereotypeApiService
   * @param entityType
   * @param cacheControl
   * @returns {Observable<OrgaResponse<StereotypeListDto>>}
   */
  getStereotypeFilterByEntityType(entityType: AppEntityType, cacheControl: string): Observable<OrgaResponse<StereotypeListDto>> {
    const options = {
      params: {
        entityType: entityType.toString(),
        Cols: 'CustomPropertySets',
        'cache-control': cacheControl
      }
    }
    return super.get<OrgaResponse<StereotypeListDto>>(`/tech/stereotype`, undefined, options);
  }

  /**
   * @name postStereotypeFilterByEntityTypePaginated
   * @description
   * post paginated stereotype filter by entity type
   * @memberof TeamStereotypeApiService
   * @param paramsPaginated
   * @param body
   * @return {Observable<OrgaResponse<StereotypeListDto[]>>}
   */
  postStereotypeFilterByEntityTypePaginated(paramsPaginated: PaginationParams, body: any): Observable<OrgaResponse<StereotypeListDto[]>> {
    const options: RequestOptions = {
      params: {
        PageSize: paramsPaginated.pageSize.toString(),
        PageNumber: paramsPaginated.pageNumber.toString(),
        Cols: paramsPaginated.cols,
        ...(paramsPaginated.entityType && { entityType: paramsPaginated.entityType.toString() }),
        ...(paramsPaginated.archived && { archived: paramsPaginated.archived.toString() }),
      }

    }
    return super.post<OrgaResponse<StereotypeListDto[]>>(`/tech/stereotype/filter`, undefined, body, options);
  }
}
