import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {filter, mergeMap, of, withLatestFrom} from 'rxjs';
import {catchError, exhaustMap, map, tap} from 'rxjs/operators';
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {AppType} from "@shared/models/AppType";
import {Clients, InvitationCheckDto, RegisterUserDto, ResetPasswordDto, TokenDto} from "@server-models";
import {TranslateService} from "@ngx-translate/core";
import {ModalControllerService} from "@shared/services/modal-controller.service";
import {TeamLoginSideEffectsService} from "@team/pages/login/services/team-login-side-effects.service";
import {TeamLoginActions} from "@team/pages/login/store/team-login.actions-type";
import {LoginBaseEffects} from "@shared/stores/login-base/store/login-base.effects";
import {LoginBaseStorageService} from "@shared/stores/login-base/services/login-base-storage.service";
import {AlertBaseControllerService} from "@shared/services/alert-controller.service";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";
import {TeamLoginApiService} from "@team/pages/login/services/team-login-api.service";
import {LoginBaseActions} from "@shared/stores/login-base/store/login-base.actions-type";
import {RegisterGuestAction} from "@team/pages/login/interfaces/register-guest-action.interface";
import {TeamLoginStorageService} from "@team/pages/login/services/team-login-storage.service";
import {LanguageService} from "@shared/services/language.service";

@Injectable({
  providedIn: 'root'
})
export class TeamLoginEffects extends LoginBaseEffects {

  constructor(
    _store: Store,
    actions$: Actions,
    private _teamLoginApiService: TeamLoginApiService,
    private _loginTeamSideEffectsService: TeamLoginSideEffectsService,
    private _teamLoginStorage: TeamLoginStorageService,
    loginStorage: LoginBaseStorageService,
    _alertControllerService: AlertBaseControllerService,
    _translationService: TranslateService,
    _modalControllerService: ModalControllerService,
    _router: Router,
    _languageService: LanguageService,
  ) {
    super(
      _store,
      actions$,
      loginStorage,
      _alertControllerService,
      _translationService,
      _modalControllerService,
      _router,
      _languageService
    )
  }

  changePasswordStart$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.changePassword),
    exhaustMap(action => {
      return this._teamLoginApiService.changePassword(action.email, action.oldPasswordBase64, action.newPasswordBase64).pipe(
        map(() => TeamLoginActions.changePasswordSuccess()),
        catchError(error => of(TeamLoginActions.changePasswordFail({error})))
      )
    })
  ));

  changePasswordSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.changePasswordSuccess),
    map(() => this._router.navigateByUrl("team/login"))
  ), {dispatch: false});

  resetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.resetPassword),
    withLatestFrom(this._store.select(TeamLoginSelectors.selectToken)),
    exhaustMap(([action, tokenDto]) => {
      const resetData: ResetPasswordDto = {
        token: tokenDto?.token,
        ...action.resetData
      }
      return this._teamLoginApiService.resetPassword(resetData).pipe(
        map(() => TeamLoginActions.resetPasswordSuccess({
          email: action.resetData.email!,
          password: action.resetData.newPassword!
        })),
        catchError(error => of(TeamLoginActions.resetPasswordFail({error})))
      )
    })
  ));

  resetPasswordSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.resetPasswordSuccess),
    exhaustMap((action) => {
      this._modalControllerService.closeModal();
      return [TeamLoginActions.byPassword({login: action.email, password: action.password})];
    })
  ));

  resetRequestPassword$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.resetRequestPassword),
    exhaustMap(action => this._teamLoginApiService.resetRequestPassword(action.email)
      .pipe(
        map(() => {
          this._loginTeamSideEffectsService.resetRequestPasswordAlert();
          return TeamLoginActions.resetRequestPasswordSuccess();
        }),
        catchError(error => [TeamLoginActions.resetRequestPasswordFail({error})])
      ))
  ));

  loginByPassword$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.byPassword),
    exhaustMap(action => {
      return this._teamLoginApiService.login(action.login, action.password!, action.passwordBase64).pipe(
        map(data => TeamLoginActions.byPasswordSuccess({token: data})),
        catchError((error) => [TeamLoginActions.byPasswordFail({error})])
      )
    })
  ));

  loginByPasswordSuccess = createEffect(() => this.actions$.pipe(
      ofType(TeamLoginActions.byPasswordSuccess),
      exhaustMap(action => {
        if (action.token?.info?.isGuest) {
          this._modalControllerService.closeModal();
          this._router.navigateByUrl("team/welcome");
        }
        return [TeamLoginActions.initSuccess({token: action.token, app: AppType.Team, isLogging: true})]
      }),
      catchError((error) => [TeamLoginActions.initFail({error})])
    )
  );

  // Registration Guest - Start -

  initRegistrationGuest$ = createEffect(() => this.actions$.pipe(
      ofType(TeamLoginActions.initRegistrationGuest),
      exhaustMap(action => {
        return [TeamLoginActions.initRegistrationGuestDialogOpen({currentEmail: action.currentEmail})]
      }),
      catchError((error) => [TeamLoginActions.initRegistrationGuestFail({error})])
    )
  );

  initRegistrationGuestDialogOpen$ = createEffect(() => this.actions$.pipe(
      ofType(TeamLoginActions.initRegistrationGuestDialogOpen),
      exhaustMap((action: { currentEmail: string | undefined }) => {
        const controlEmail = action.currentEmail ? action.currentEmail : "";

        this._loginTeamSideEffectsService.askRegistrationData(controlEmail);
        return [TeamLoginActions.initRegistrationGuestSuccess()]
      }),
    )
  );

  registerGuestPinRequest$ = createEffect(() => this.actions$.pipe(
      ofType(TeamLoginActions.registerGuestPinRequest),
      exhaustMap((action: { registerGuest: RegisterGuestAction, isLoadingId?: string | number }) => {
        return this._teamLoginApiService.requestGuestPin(action.registerGuest.email).pipe(
          mergeMap(() =>
            [TeamLoginActions.registerGuestPinRequestSuccess({
              isRetry: action.registerGuest.isRetry,
              email: action.registerGuest.email,
              passwordBase64: action.registerGuest.passwordBase64
            })]
          ),
          catchError((error) =>
            [TeamLoginActions.registerGuestPinRequestFail({error: error})]
          )
        )
      })
    )
  );

  registerGuestPinRequestSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(TeamLoginActions.registerGuestPinRequestSuccess),
      exhaustMap((action: RegisterGuestAction) => {
        if (!action.isRetry) {
          this._loginTeamSideEffectsService.askRegistrationPinGuest(action.passwordBase64, action.email);
        }
        return [TeamLoginActions.registerGuestPinDialog({})]
      }),
    )
  );

  registerGuestAsUser$ = createEffect(() => this.actions$.pipe(
      ofType(TeamLoginActions.registerGuestAsUser),
      exhaustMap((action: { data: RegisterUserDto, isLoadingId: number | string, isRetry?: boolean }) => {
        return this._teamLoginApiService.requestGuestAsUser(action.data.pinFromEmail!, action.data.email!, action.data.passwordBase64!).pipe(
          map(() => {
              return TeamLoginActions.registerGuestAsUserSuccess({
                isRetry: action.isRetry!,
                email: action.data.email!,
                passwordBase64: action.data.passwordBase64!
              })
            }
          ),
          catchError((error) =>
            [TeamLoginActions.registerGuestAsUserFail({error: error})]
          )
        )
      })
    )
  );

  registerGuestAsUserSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(TeamLoginActions.registerGuestAsUserSuccess),
      exhaustMap((action: {
          isRetry: boolean;
          email: string;
          passwordBase64: string;
        }) => {
          return [TeamLoginActions.byPassword({
            login: action.email!,
            password: undefined,
            passwordBase64: action.passwordBase64
          })]
        }
      )
    )
  );

  // Registration Guest - ACCEPT START -
  initInvitationGuestCode$ = createEffect(() => this.actions$.pipe(
      ofType(TeamLoginActions.initInvitationCodeGuest),
      exhaustMap(() => {
        return [TeamLoginActions.invitationCodeGuestCheckDialogOpen()]
      }),
      catchError((error) => [TeamLoginActions.initInvitationCodeGuestFail({error})])
    )
  );

  invitationCodeGuestCheckDialogOpen$ = createEffect(() => this.actions$.pipe(
      ofType(TeamLoginActions.invitationCodeGuestCheckDialogOpen),
      map(() => {
        this._loginTeamSideEffectsService.askForTeamCode();
      }),
    ), {dispatch: false}
  );

  invitationCodeDialogOpen$ = createEffect(() => this.actions$.pipe(
      ofType(TeamLoginActions.invitationCodeDialogOpen),
      exhaustMap((action: { code: string }) => {
        this._loginTeamSideEffectsService.askForInvitationCode(action.code);
        return [TeamLoginActions.initInvitationCodeGuestSuccess()]
      }),
    )
  );

  invitationCodeGuestCheck$ = createEffect(() => this.actions$.pipe(
      ofType(TeamLoginActions.invitationCodeGuestCheck),
      exhaustMap((action: { code: string }) => {
        return this._teamLoginApiService.requestInvitationCodeCheck(action.code).pipe(
          map((response: InvitationCheckDto) => {
              if (response.isRevoked) {
                return TeamLoginActions.invitationCodeGuestCheckRevoked()
              } else {
                return TeamLoginActions.invitationCodeGuestCheckSuccess({code: action.code})
              }
            }
          ),
          catchError((error) =>
            [TeamLoginActions.invitationCodeGuestCheckFail({error: error})]
          )
        )
      })
    )
  );

  invitationCodeGuestCheckRevoked$ = createEffect(() => this.actions$.pipe(
      ofType(TeamLoginActions.invitationCodeGuestCheckRevoked),
      exhaustMap(() => {
        this._loginTeamSideEffectsService.invitationCodeRevoked();
        return [TeamLoginActions.invitationCodeGuestCheckRevokedDisplayed()]
      }),
    )
  );

  invitationCodeGuestCheckSuccess$ = createEffect(() => this.actions$.pipe(
      ofType(TeamLoginActions.invitationCodeGuestCheckSuccess),
      map((action: { code: string }) => {
        this._loginTeamSideEffectsService.askForInvitationCode(action.code);
      }),
    ), {dispatch: false}
  );

  invitationCodeGuestAccept$ = createEffect(() => this.actions$.pipe(
      ofType(TeamLoginActions.invitationCodeGuestAccept),
      exhaustMap((action: { code: string, lastName: string, pin: string }) => {
        return this._teamLoginApiService.requestInvitationCodeAccept(action.code, action.lastName, action.pin).pipe(
          exhaustMap((data) => {
              return [TeamLoginActions.invitationCodeGuestAcceptSuccess({tenantId: data.tenantId})]
            }
          ),
          catchError((error) =>
            [TeamLoginActions.invitationCodeGuestAcceptFail({error: error})]
          )
        )
      })
    )
  );

  invitationCodeGuestAcceptSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.invitationCodeGuestAcceptSuccess),
    exhaustMap(() => {
      this._modalControllerService.closeModal();
      return [TeamLoginActions.loginRefresh({clientType: Clients.Team, isLogging: true})]
    })
  ));

  // Registration Guest - ACCEPT END -

  // Registration Guest - End -

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.initSuccess),
    filter(action => action.app == AppType.Team),
    mergeMap((action) => {
      const info = action.token!?.info!;
      this._languageService.setLanguage(info.userCulture!);

      const multipleTenants = this._loginTeamSideEffectsService.multipleTenants(info);

      if (!multipleTenants) {

        const tenant = info?.tenants![0];
        const tenantId = tenant?.tenantId;

        return [TeamLoginActions.successUniqueTenant({
          app: action.app,
          token: action.token,
          currentTenant: {
            tenantId,
            isGuest: info?.isGuest!,
            tenantLanguage: info?.userCulture,
            tenantDisplayName: tenant?.displayName
          },
          isLogging: action.isLogging!
        })];
      } else {
        return [TeamLoginActions.successMultiTenant({tokenInfo: info})];
      }
    })
  ));

  successUniqueTenant$ = createEffect(() => this.actions$.pipe(
      ofType(TeamLoginActions.successUniqueTenant),
      exhaustMap((action) => {
          const tokenInfo = action.token!?.info!;
          this._teamLoginStorage.set({
            app: action.app,
            token: this._stripJwt(action.token!),
            currentTenant: {
              tenantLanguage: tokenInfo?.userCulture,
              tenantId: action.currentTenant?.tenantId,
              isGuest: tokenInfo?.isGuest!,
              tenantDisplayName: this._selectedTenantDisplayName(tokenInfo?.tenants!, action.currentTenant?.tenantId!)
            },
            isLogging: action.isLogging
          });

          return [TeamLoginActions.loginRefresh({clientType: Clients.Team, isLogging: action.isLogging})];
        }
      )
    )
  );

  successMultiTenant$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.successMultiTenant),
    tap((action) => {
      this._loginTeamSideEffectsService.getTenants(action.tokenInfo).then();
    })
  ), {dispatch: false});

  existingMultiTenant$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.existingMultiTenant),
    map((action) => {
      return TeamLoginActions.successSelectedTenant({tenantId: action.tenantId});
    })
  ));

  cancelSelectedTenant$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.cancelSelectedTenant),
    tap(() => {
      this._router.navigateByUrl("team/login")
    })
  ), {dispatch: false});

  successSelectedTenant$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.successSelectedTenant),
    withLatestFrom(this._store.select(TeamLoginSelectors.selectCurrentApp)),
    withLatestFrom(this._store.select(TeamLoginSelectors.selectToken)),
    exhaustMap(([[action, app], tokenDto]) => {
      this._teamLoginStorage.set({
        app: app,
        token: this._stripJwt(tokenDto!)!,
        currentTenant: {
          tenantId: action.tenantId,
        },
        isLogging: action.isLogging
      })
      return [
        TeamLoginActions.loginRefresh({clientType: Clients.Team}),
      ]
    })
  ));

  loginInit$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.init),
    mergeMap(() => {
      return [TeamLoginActions.loadStorage()]
    })
  ));

  loadStorage$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.loadStorage),
    mergeMap(() => {
      return this._teamLoginStorage.getSt().pipe(
        mergeMap((data) => {
          let tenantDisplayName: string;
          if (data) {
            if (data.token) {
              if (data.currentTenant?.tenantId) {
                tenantDisplayName = this._selectedTenantDisplayName(data.token!.info!.tenants!, data.currentTenant.tenantId!)!;
              }
            }
            return [TeamLoginActions.loadStorageSuccess(
              {
                tenantSettings: data.currentTenant?.tenantSettings,
                tenantId: data.currentTenant?.tenantId!,
                tenantLanguage: data.currentTenant?.tenantLanguage!,
                isGuest: data.currentTenant?.isGuest!,
                tenantDisplayName: tenantDisplayName!,
                tokenDto: data.token!,
                app: data.app!,
                isLogging: data.isLogging!
              }
            )]
          }
          return [TeamLoginActions.loadStorageFail({error: 'There is not Storage data'})]
        })
      )
    })
  ));

  loadStorageSuccess$ = createEffect((() => this.actions$.pipe(
    ofType(TeamLoginActions.loadStorageSuccess),
    exhaustMap((action) => {
      if (action?.tenantSettings?.primaryColor?.value) {
        this._setTenantColor(action.tenantSettings?.primaryColor!.value!);
      }

      return [TeamLoginActions.initSuccess({
        app: action.app!,
        token: {
          ...action.tokenDto,
          token: action.tokenDto?.token || undefined
        },
        currentTenant: {
          tenantLanguage: action.tenantLanguage,
          isGuest: action.isGuest,
          tenantId: action.tenantId,
          tenantDisplayName: action.tenantDisplayName,
          tenantSettings: action.tenantSettings,
        },
        isLogging: action.isLogging!
      })]

    })
  )));

  loginRefreshStart$ = createEffect(() => this.actions$.pipe(
      ofType(TeamLoginActions.loginRefresh),
      withLatestFrom(this._store.select(TeamLoginSelectors.selectToken)),
      withLatestFrom(this._store.select(TeamLoginSelectors.selectTenantId)),
      exhaustMap(([[action, token], tenantId]) => {
          return this._teamLoginApiService.loginRefresh(token?.refreshToken!, tenantId!).pipe(
            map((tokenDto: TokenDto) => {
              return TeamLoginActions.loginRefreshSuccess({
                tokenDto,
                tenantId: tenantId!,
                clientType: Clients.Team,
                isLogging: action.isLogging!
              })
            }),
            catchError((error) => [TeamLoginActions.loginRefreshFail({error})])
          )
        }
      )
    )
  );

  loginRefreshSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.loginRefreshSuccess),
    withLatestFrom(this._store.select(TeamLoginSelectors.selectToken)),
    withLatestFrom(this._store.select(TeamLoginSelectors.selectCurrentApp)),
    exhaustMap(([[action], appType]) => {
        this._teamLoginStorage.set({
          app: appType,
          token: this._stripJwt(action.tokenDto!),
          currentTenant: {
            tenantId: action.tenantId,
          },
          isLogging: action.isLogging
        });

        if (!action.tenantId) {
          return [TeamLoginActions.fetchTenantSettingsCancel()]
        }
        return [TeamLoginActions.fetchTenantSettings({tenantId: action.tenantId})]
      }
    )
  ));

  fetchTenantSettingsCancel$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.fetchTenantSettingsCancel),
    exhaustMap(() => [TeamLoginActions.navigationToWelcome()])
  ));

  navigationToWelcome$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.navigationToWelcome),
    tap(() => {
      this._modalControllerService.closeModal();
      this._router.navigateByUrl("team/welcome")
    })
  ), {dispatch: false});


  loginFetchTenantSettings$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.fetchTenantSettings),
    withLatestFrom(this._store.select(TeamLoginSelectors.selectTenantId)),
    withLatestFrom(this._store.select(TeamLoginSelectors.selectToken)),
    exhaustMap(([[_, tenantId], tokenDto]) => {
      const tokenInfo = tokenDto!?.info!;
      return this._teamLoginApiService.getSettings(tenantId!).pipe(
        map((settings) => {
          return TeamLoginActions.fetchTenantSettingsSuccess({
            currentTenant: {
              tenantId: tenantId,
              tenantLanguage: tokenInfo?.userCulture,
              isGuest: tokenInfo?.isGuest!,
              tenantDisplayName: this._selectedTenantDisplayName(tokenInfo?.tenants!, tenantId!),
              tenantSettings: settings
            }
          })
        }),
        catchError(error => [LoginBaseActions.fetchTenantSettingsFail({error})])
      )
    })
  ));

  loginFetchTenantSettingsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.fetchTenantSettingsSuccess),
    withLatestFrom(this._store.select(TeamLoginSelectors.selectCurrentApp)),
    withLatestFrom(this._store.select(TeamLoginSelectors.selectToken)),
    exhaustMap(([[action, appType], tokenDto]) => {
      if (action?.currentTenant?.tenantSettings?.primaryColor?.value) {
        this._setTenantColor(action.currentTenant.tenantSettings!?.primaryColor!?.value!);
      }
      this._teamLoginStorage.set({
        app: appType,
        token: this._stripJwt(tokenDto!),
        currentTenant: action.currentTenant
      });
      return [TeamLoginActions.isLoggingNavigation()]
    }),
  ));

  isLoggingNavigation$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.isLoggingNavigation),
    withLatestFrom(this._store.select(TeamLoginSelectors.selectIsLogging)),
    exhaustMap(([_, isLogging]) => {
      if (isLogging) {
        return [TeamLoginActions.isLoggingNavigationDone()]
      } else {
        return [TeamLoginActions.isLoggingNavigationCanceled()]
      }
    })
  ));

  isLoggingNavigationDone$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.isLoggingNavigationDone),
    exhaustMap(() => [TeamLoginActions.navigationToInfos()])
  ));

  navigationToInfos$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.navigationToInfos),
    tap(() => this._router.navigateByUrl("team/logged/infos"))
  ), {dispatch: false});

  loginRefreshFail$ = createEffect(() => this.actions$.pipe(
    ofType(TeamLoginActions.loginRefreshFail), exhaustMap(() =>
      this._alertControllerService.observableRefreshToken(Clients.Team))
  ), {dispatch: false});
}
