import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-base-search-bar',
  templateUrl: './base-search-bar.component.html',
  styleUrls: ['./base-search-bar.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule]

})
export class BaseSearchBarComponent {

  @Output() valueToSearch: EventEmitter<string>  = new EventEmitter<string>();

  /**
   * @name onSearch
   * @description
   * emit the value of search bar on input with debounce of 500
   * @memberof BaseSearchBarComponent
   * @param event
   */
  onSearch(event: CustomEvent): void {
    const searchValue = event?.detail?.value ?? '';
    this.valueToSearch.emit(searchValue);
  }

}
